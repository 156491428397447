export const salesforceLead = {
  type: 'salesforce-lead',
  name: 'Salesforce Lead',
  icon: 'fas fa-envelope',
  settings: {},
  formSchema: {
    properties: {
      name: {
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      leadSource: {
        label: 'Lead Source',
        type: 'string',
        showInForm: true,
        formFieldType: 'select',
        options: [['Captains Club', 'Captains Club'], ['Superports Listing', 'Superports Listing']]
      },
      buttonCaption: {
        label: 'Button Caption',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      description: {
        label: 'Description',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      },
      modalTitle: {
        label: 'Modal Title',
        type: 'string',
        showInForm: true,
        formFieldType: 'text'
      }
    },
    required: ['name', 'leadSource', 'buttonCaption', 'description', 'modalTitle']
  },
  allowChildren: true,
  color: 'green',
  group: 'ui'
};
