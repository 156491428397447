import React from 'react';
import { IoBoatOutline } from 'react-icons/io5';

const YachtIcon = () => (
  <span>
    <IoBoatOutline style={{ color: '#63779C', width: '24px', height: '24px' }} />
  </span>
);

export default YachtIcon;
