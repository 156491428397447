const commonProperties = {
  name: {
    type: 'string',
    showInForm: true,
    formFieldType: 'text'
  },
  modifier: {
    label: 'Modifier',
    type: 'string',
    showInForm: true,
    formFieldType: 'text'
  }
};

const editorViewProperties = {
  canShowInEditorView: {
    type: 'boolean',
    showInForm: false,
    default: true
  },
  showInEditorView: {
    type: 'boolean',
    showInForm: false,
    default: true
  }
};

export { editorViewProperties, commonProperties };
